import React from 'react'
import Vivus from 'vivus'

import useVivusSVGDraw from '../hooks/useVivusSVGDraw'
import squiggleSVGData from './squiggleSVGData'
import './Squiggle.css'

const Squiggle = ({ type = 0, color = 'gray', ...rest }) => {
  const [ref] = useVivusSVGDraw({
    type: 'delayed',
    start: 'inViewport',
    delay: 10 * type,
    animTimingFunction: Vivus.EASE,
    duration: 60 + 10 * type,
  })

  const svgData = squiggleSVGData[type % squiggleSVGData.length]

  return (
    <svg ref={ref} className="squiggle" {...rest}>
      {/* empty path to allow delay when using inViewport trigger */}
      <path />
      <path
        d={svgData}
        style={{ fill: 'none', stroke: color, strokeWidth: '3.5px' }}
      />
    </svg>
  )
}

export default Squiggle
