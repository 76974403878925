import { useRef, useEffect, useState } from 'react'
import Vivus from 'vivus'
import noop from 'lodash/noop'

function useVivusSVGDraw(options = {}, animationFinishedCallback = noop) {
  const ref = useRef()
  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const animation = new Vivus(ref.current, options, animationFinishedCallback)
    setAnimation(animation)

    return () => {
      animation.destroy()
    }
  }, [ref.current])

  return [ref, animation]
}

export default useVivusSVGDraw
